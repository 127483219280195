import { Injectable } from '@angular/core';
import { Dataset, DatasetAddress } from '@sympheny/gis/datasets';
import {
  Address,
  CustomerLayer,
  HubFeature,
  LayerType,
  NetworkGis,
} from '@sympheny/project/scenario/data-access';
import View from 'ol/View';
import { Observable } from 'rxjs';

import { MapperDataType } from './mapper';
import { HubProcessed } from '../layer/map-hub-layer';
import { MapLayer } from '../layer/map-layer';

export interface MapState {
  layer: Record<LayerType, MapLayer[]>;
  dataset: Dataset | null;
  datasetData: DatasetAddress[];
  address: Address | null;
  addressProcessed: HubProcessed | null;
  view: View;
  featureData: any[];
}

export interface DataType {
  [LayerType.hubs]: HubFeature;
  [LayerType.customerLayer]: CustomerLayer;
  [LayerType.customerScenarioLayer]: CustomerLayer;
  [LayerType.networkLayers]: NetworkGis;
}

@Injectable()
export abstract class MapStore<STATE extends MapState = MapState> {
  public readonly dataSetAddress$: Observable<any>;

  public abstract getLayers<
    TYPE extends LayerType,
    DATA = MapperDataType[TYPE],
  >(layerType: TYPE): DATA[];

  public abstract getLayer<TYPE extends LayerType, DATA = MapperDataType[TYPE]>(
    layerType: TYPE,
    layerId: string,
  ): DATA | null;

  public abstract selectAddress(
    address: Address | null,
    processed: HubProcessed | null,
  );

  public abstract showFeatureData(features: any[]);

  public abstract getView();

  public abstract toggleCo2Range(toggle: boolean);

  public abstract delete(
    layerType: LayerType,
    scenarioId: string,
    layerId: string,
  );

  public abstract create<TYPE extends LayerType>(
    layerType: TYPE,
    scenarioId: string,
    data: Partial<DataType[TYPE]>,
    extra?: any,
  );

  public abstract update<TYPE extends LayerType>(
    layerType: TYPE,
    scenarioId: string,
    layerId: string,
    data: Partial<DataType[TYPE]>,
    extra?: any,
  );

  public abstract selectValue<K extends keyof STATE>(
    key: K,
  ): Observable<STATE[K]>;

  public abstract changeDataset(dataset: Dataset | null);

  public abstract getLayers$<
    TYPE extends LayerType,
    DATA = MapperDataType[TYPE],
  >(layerType: TYPE): Observable<DATA[]>;

  public abstract loadLayer(layerType: LayerType, layerId: string);

  public abstract getLayer$(layerType: LayerType, layerId: string);
}
