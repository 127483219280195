import { Feature, FeatureCollection } from 'geojson';

import { Address } from './address';

export class DatasetFilter {
  dataset_id: string;
  fields: [
    {
      default_name: string;
      custom_name: string;
      value: string;
    },
  ];
}

export class HubGis {
  scenario_id: string;
  hub_id: string;
  hub_name: string;
  addresses: Address[];
  base_layer: FeatureCollection;
  building_layer: FeatureCollection | null;
  facade_layer: FeatureCollection;
  roof_layer: FeatureCollection;
  parcel_layer: FeatureCollection;
  dataset_filters: DatasetFilter[];
  sep: boolean;
  geoadmin: boolean;
}

export class HubFeature {
  hub_id: string;
  hub_name: string;
  feature: Feature;
  dataset_filters: DatasetFilter[];
  sep: boolean;
  geoadmin: boolean;
}
