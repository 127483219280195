import { Injectable, OnDestroy } from '@angular/core';
import { gisStyles } from '@sympheny/gis/utils';
import { LayerType } from '@sympheny/project/scenario/data-access';
import VectorLayer from 'ol/layer/Vector';
import { EMPTY, mapTo, merge, Observable, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { createLayer } from './create-layer';
import { DrawLayerService } from './draw-layer.service';
import { MapHubLayer } from './map-hub-layer';

@Injectable()
export class DrawHubsLayersService
  extends DrawLayerService
  implements OnDestroy
{
  private baseLayer: VectorLayer<any>;
  private parcelLayer: VectorLayer<any>;
  private roofLayer: VectorLayer<any>;
  private buildingLayer: VectorLayer<any>;
  private facadeLayer: VectorLayer<any>;
  private readonly destroySingleHub$ = new Subject<void>();
  private zoomToHubCenter: string | undefined;
  private firstZoom = true;

  protected init(): void {
    const { enableLayer, visibleLayer } = this.config;
    const addAll = !enableLayer;

    this.baseLayer = createLayer({
      style: gisStyles.savedFeatures,
      title: 'Hubs',
      zIndex: 3,
      visible: visibleLayer?.parcel ?? true,
    });
    this.roofLayer = createLayer({
      style: gisStyles.savedFeatures,
      title: 'Roof Layer',
      zIndex: 2,
      visible: visibleLayer?.parcel ?? true,
    });
    this.buildingLayer = createLayer({
      style: gisStyles.savedFeatures,
      title: 'Building Layer',
      zIndex: 2,
      visible: visibleLayer?.parcel ?? true,
    });
    this.parcelLayer = createLayer({
      style: gisStyles.savedFeatures,
      title: 'Parcel Layer',
      zIndex: 1,
      visible: visibleLayer?.parcel ?? false,
    });
    this.facadeLayer = createLayer({
      style: gisStyles.savedFeatures,
      title: 'Facade Layer',
      zIndex: 0,
      visible: visibleLayer?.parcel ?? false,
    });

    if (addAll || enableLayer.hubs) this.map.addLayer(this.baseLayer);

    if (addAll || enableLayer.parcel) this.map.addLayer(this.parcelLayer);

    if (addAll || enableLayer.roof) this.map.addLayer(this.roofLayer);
    if (addAll || enableLayer.building) this.map.addLayer(this.buildingLayer);

    if (addAll || enableLayer.facade) this.map.addLayer(this.facadeLayer);
  }

  public getDownloadableLayers(): VectorLayer<any>[] {
    return [this.parcelLayer];
  }

  public drawHub(hubGuid: string) {
    return;
    if (!(this.config.select.features || this.config.hub.edit)) {
      return;
    }
    this.clearLayers();

    merge(this.drawSingleHubEffect(hubGuid))
      .pipe(takeUntil(this.destroy$), takeUntil(this.destroySingleHub$))
      .subscribe();
  }

  private drawSingleHubEffect(hubGuid: string) {
    return EMPTY;
    const hubLayer = this.scenarioMapStore.getLayer$(
      LayerType.hubs,
      hubGuid,
    ) as any;

    return hubLayer.pipe(
      tap((layer: MapHubLayer | undefined) => {
        this.clearLayers();
        if (!layer) {
          return;
        }
        this.addHubToLayers(layer);
        this.zoomToHub(hubGuid);
      }),
    );
  }

  protected getEffects(): Observable<void>[] {
    return [this.hubLayersEffect()];
  }

  private hubLayersEffect() {
    // if (this.config.hub.edit || this.config.select.features) {
    //   return EMPTY;
    // }

    const hubs$ = this.scenarioMapStore.getLayers$(LayerType.hubs);

    this.clearLayers();

    return hubs$.pipe(
      tap((layers) => {
        this.clearLayers();
        layers.forEach((layer) => this.addHubToLayers(layer));

        if (this.firstZoom) {
          this.zoomToHub(this.zoomToHubCenter);
          this.firstZoom = layers.length === 0;
        }
      }),
      mapTo(undefined),
    );
  }

  public zoomToHub(hub: string | undefined) {
    let hubLayer = hub
      ? (this.scenarioMapStore.getLayer(LayerType.hubs, hub) as MapHubLayer)
      : null;

    if (!hubLayer && hub) {
      const hubLayers = this.scenarioMapStore.getLayers(LayerType.hubs);
      hubLayer = hubLayers.find((h) => h.hubName === hub);
    }
    this.zoomToHubCenter = hub;

    if (!hubLayer) {
      this.zoomToHubsCenter();

      return;
    }

    this.zoomToExtend(hubLayer.getExtend());
  }

  private zoomToHubsCenter() {
    // return;
    this.zoomToExtend(
      this.baseLayer.getSource().getExtent() as unknown as number[],
    );
  }

  private addHubToLayers(layer: MapHubLayer) {
    this.addToLayer(this.baseLayer, layer.baseLayer);

    this.addToLayer(this.facadeLayer, layer.facadeLayer);

    this.addToLayer(this.parcelLayer, layer.parcelLayer);

    this.addToLayer(this.roofLayer, layer.roofLayer);
    this.addToLayer(this.buildingLayer, layer.buildingLayer);
  }

  private clearLayers() {
    this.baseLayer.getSource().clear();
    this.roofLayer.getSource().clear();
    this.buildingLayer.getSource().clear();
    this.parcelLayer.getSource().clear();
    this.facadeLayer.getSource().clear();
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.destroySingleHub$.next();
    this.destroySingleHub$.complete();
  }
}
