import { Injectable } from '@angular/core';
import { gisStyles, skipFeaturesAtPixel } from '@sympheny/gis/utils';
import { Address, LayerType } from '@sympheny/project/scenario/data-access';
import * as ol from 'ol';
import { Feature, MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Layer, Vector } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';

import { HubProcessed, MapHubLayer } from '../layer/map-hub-layer';
import { MapStore } from '../store/map.store';

interface SelectedAddress {
  layer: Layer;
  feature: Feature;
  processed: HubProcessed;
  address: Address[];
}

@Injectable()
//TODO rename me
export class ClickAndRetrieveBuildingInformationService {
  private map!: ol.Map;
  private overlay: ol.Overlay;
  private container: HTMLElement;
  private vectorSource: VectorSource;

  private featureInfoId = '_feature_info';
  private selectedFeature?: Feature;

  constructor(private readonly scenarioMapStore: MapStore) {}

  public init(map: ol.Map, scenarioId: string): void {
    this.map = map;

    this.featureInfoId = map.get('sympenyId') + '_feature_info';

    this.map.on('singleclick', (evt: MapBrowserEvent<any>) => {
      this.selectedFeature?.setStyle(gisStyles.savedFeatures);
      this.showFeatureData(evt.coordinate);
    });

    this.vectorSource = new VectorSource({
      features: [],
    });

    const selectVector = new Vector({
      source: this.vectorSource,
    });
    const style: Style = new Style({
      image: new Circle({
        radius: 5,
        stroke: new Stroke({
          color: '#1d9682',
        }),
        fill: new Fill({
          color: '#1d9682',
        }),
      }),
    });

    selectVector.setStyle(style);

    this.map.addLayer(selectVector);
  }

  private showFeatureData(coordinate: number[]) {
    const pixel = this.map.getPixelFromCoordinate(coordinate);
    if (!this.container) {
      this.container = document.getElementById(this.featureInfoId);
      this.container.style.display = 'block';
      this.overlay = new ol.Overlay({
        element: this.container,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });
      this.map.addOverlay(this.overlay);
    }

    const { features, address, processed } = this.getFeaturesAt(
      coordinate,
      pixel,
    );
    const position = features?.length ? coordinate : null;

    this.overlay.setPosition(position);

    this.scenarioMapStore.showFeatureData(features);
    this.scenarioMapStore.selectAddress(address, processed);

    this.drawSelectedPoint(address);
  }

  private drawSelectedPoint(address: Address | null) {
    this.vectorSource.clear();

    if (address) {
      const coordinates = fromLonLat([address.lon, address.lat]);
      this.vectorSource.addFeature(new Feature(new Point(coordinates)));
    }
  }

  private getFeaturesAt(coordinate: number[], pixel: number[]): any {
    const features: any[] = [];
    const addresses: SelectedAddress[] = [];

    this.map.forEachFeatureAtPixel(
      pixel,
      (f: FeatureLike, layer: any) => {
        if (!(f instanceof Feature)) {
          return;
        }

        const checkAddress = this.checkForAddress(f, layer);
        if (checkAddress) {
          addresses.push(checkAddress);
        }
        features.push({
          layer,
          feature: f,
        });
      },
      {
        layerFilter: skipFeaturesAtPixel,
      },
    );

    const { address, processed } =
      this.selectAddress(coordinate, addresses) ?? {};
    return { features, address, processed };
  }

  private checkForAddress(
    feature: Feature<any>,
    layer: Layer<any>,
  ): SelectedAddress | null {
    let hubGis: MapHubLayer | null;
    const addressId = feature.get('address_id') ?? null;

    if (feature instanceof MapHubLayer) {
      hubGis = feature;
    } else {
      const hubId = feature.get('hub_id');
      if (!hubId) {
        return null;
      }

      hubGis = this.scenarioMapStore.getLayer(
        LayerType.hubs,
        hubId,
      ) as MapHubLayer | null;
    }

    if (!hubGis) {
      return {
        address: [],
        processed: { geoadmin: false, sep: false },
        feature,
        layer,
      };
    }

    const address = hubGis.getAddressDetails(addressId);
    return { address, processed: hubGis.processed, feature, layer };
  }

  private selectAddress(coordinate: number[], addresses: SelectedAddress[]) {
    const closest = this.getClosestAddress(coordinate, addresses);

    if (!closest) {
      return null;
    }

    const { address, processed } = closest;

    return { address, processed };
  }

  private getClosestAddress(
    coordinate: number[],
    selectedAddress: SelectedAddress[],
  ): {
    address: Address;
    processed: HubProcessed;
    feature: Feature;
    layer: Layer;
  } {
    const addresses = selectedAddress
      .map((a) =>
        a.address.map((address) => ({
          feature: a.feature,
          layer: a.layer,
          address,
          processed: a.processed,
        })),
      )
      .flat();

    if (addresses.length < 1) {
      return addresses[0];
    }

    return addresses?.reduce((a, b) =>
      this.getDistance(coordinate, a.address) <
      this.getDistance(coordinate, b.address)
        ? a
        : b,
    );
  }

  private getDistance(point: number[], address: Address) {
    const address_point = fromLonLat([address.lon, address.lat]);
    return Math.sqrt(
      Math.pow(point[0] - address_point[0], 2) +
        Math.pow(point[1] - address_point[1], 2),
    );
  }
}
