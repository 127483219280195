<div [id]="mapUuid + '_feature_info'"
     #featureInfo
     class="sympheny-feature-info card shadow-sm bg-white p-2 rounded min-h-[250px]">

  <mat-tab-group
    headerPosition="below"
    [ngStyle]="{'max-height': maxHeight()}"
    (focusChange)="changeSelectedFeature($event.index)"
  >
    <mat-tab
      *ngFor="let feature of featureData; trackBy: trackByFn"
      [label]="feature.layer.get('title') ?? 'layer'"
    >
      <table mat-table [dataSource]="getProperties(feature) | keyvalue">
        <ng-container matColumnDef="key">

          <th mat-cell *matCellDef="let element">
            {{ translatePrefix + element.key | transloco }}
          </th>
        </ng-container>
        <ng-container matColumnDef="value">
          <td mat-cell *matCellDef="let element">
            <sympheny-feature-info-value
              [value]="element.value"/>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: ['key', 'value']"></tr>
      </table>
      <ng-container *ngIf="feature.layer.get('title') === 'Hubs'">
        <div class="feature-action-buttons">
          <sympheny-icon-button
            class="buildingInfoButton"
            icon="info"
            color="primary"
            tooltip="map.showBuildingInfo"
            (click)="onOpenBuildingInfo()"/>
          <sympheny-icon-button
            *ngIf="editHub"
            class="buildingEditButton"
            icon="edit"
            color="primary"
            tooltip="HUBS.editButton"
            (click)="onEditHub(feature)"
          />
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

