import { KeyValuePipe, NgFor, NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoPipe } from '@ngneat/transloco';
import { gisStyles } from '@sympheny/gis/utils';
import { Hub } from '@sympheny/project/data-access';
import {
  ScenarioEditDialogService,
  ScenarioHubEditDialog,
} from '@sympheny/project/scenario/edit';
import { IconButtonComponent } from '@sympheny/ui/button';
import { Feature } from 'ol';
import { Layer } from 'ol/layer';

import { FeatureInfoValueComponent } from './feature-info-value/feature-info-value.component';

export interface FeatureLayerInfo {
  feature: Feature;
  layer: Layer;
}

@Component({
  selector: 'sympheny-feature-info',
  templateUrl: './feature-info.component.html',
  styleUrls: ['./feature-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCardModule,
    MatTabsModule,
    NgFor,
    MatTableModule,
    FeatureInfoValueComponent,
    NgIf,
    IconButtonComponent,
    KeyValuePipe,
    TranslocoPipe,
    NgStyle,
  ],
})
export class FeatureInfoComponent implements OnChanges {
  @Input() public mapUuid: string;
  @Input() public scenarioId: string;
  @Input() public hubs: Hub[];
  @Input() public featureData: FeatureLayerInfo[];
  @Output() public readonly openBuildingInfo = new EventEmitter<void>();

  @ViewChild('featureInfo', { static: true })
  public readonly featureInfo: ElementRef;
  public translatePrefix: string;

  private selectedFeature: Feature;

  public readonly maxHeight = signal('50VH');

  constructor(
    @Optional()
    @Inject(ScenarioHubEditDialog)
    private readonly hubsService: ScenarioEditDialogService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly elementRef: ElementRef,
  ) {}

  public get editHub() {
    return !!this.hubsService;
  }

  private resize() {
    const { top: targetY } =
      this.featureInfo.nativeElement.getBoundingClientRect();
    const { height: elementH, top: elementY } =
      this.elementRef.nativeElement.parentElement.getBoundingClientRect();

    const offsetY = targetY - elementY;
    let newHeight = Math.round(elementH - offsetY - 30);
    if (newHeight < 250) newHeight = 250;

    this.maxHeight.set(`${newHeight}px`);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['featureData']) {
      if (this.featureData) {
        this.changeSelectedFeature(0);

        window.setTimeout(() => {
          this.resize();
        });
      } else {
        this.maxHeight.set('250px');
      }
    }
  }

  public changeSelectedFeature(index: number) {
    this.selectedFeature?.setStyle(gisStyles.savedFeatures);
    const feature = this.featureData[index] ?? null;
    this.selectedFeature = feature?.feature ?? null;
    this.selectedFeature?.setStyle(gisStyles.selectedFeatures);
    if (!this.selectedFeature) {
      return;
    }

    const title = feature.layer.get('title');
    const translate = title === 'Building Layer' ? 'BUILDING_LAYER' : '';
    this.translatePrefix = translate ? `${translate}.` : '';

    this.resize();
  }

  public getProperties(feature: FeatureLayerInfo) {
    const properties = { ...feature.feature.getProperties() };
    delete properties['geometry'];

    return properties;
  }

  public onOpenBuildingInfo() {
    this.openBuildingInfo.emit();
  }

  public onEditHub(feature: FeatureLayerInfo) {
    const hub = this.hubs.find(
      (h) => h.hubGuid === feature.feature.get('hub_id'),
    );

    if (!hub) {
      return;
    }

    this.hubsService.edit(hub.hubGuid, this.viewContainerRef);
  }

  public trackByFn(index: number, item: FeatureLayerInfo) {
    return [item.feature.get('title'), item.feature.get('hub_id')].join('-');
  }
}
